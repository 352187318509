<template>
  <section class="section-hero">
    <div class="hero container">
      <div class="hero-content hidden-content">
        <h1 class="heading-primary">Start your week in the know.</h1>
        <p class="hero-description">
          The once-a-week newsletter packed with financial markets news, events,
          and humour to keep you in the loop.
        </p>
        <form @submit.prevent="submitNewsletter" v-if="!emailSubmitted">
          <input
            type="email"
            placeholder="name@email.com"
            v-model="userEmail"
          />
          <button>Subscribe</button>
        </form>
        <p class="hero-description" v-else>You're in! 👊 Look out for a new Well-VRSD email every Monday morning!</p>
        <div class="join-flex">
          <div class="join-users">
            <img src="../../assets/nick.jpg" alt="User Photo" />
            <img src="../../assets/julie.jpg" alt="User Photo" />
            <img src="../../assets/nickp.jpg" alt="User Photo" />
            <img src="../../assets/reni.png" alt="User Photo" />
          </div>
          <p class="join-text" v-if="!emailSubmitted">Join <span>hundreds</span> of DIY investors!</p>
          <p class="join-text" v-else>You joined <span>hundreds</span> of DIY investors!</p>
        </div>
      </div>
      <div class="hero-img-box hidden-img">
        <img
          class="hero-img"
          alt="Screenshot of the INVRS markets page, including stock market data"
          src="../../assets/mobile-newsletter.png"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      userEmail: "",
      portalId: "20584317",
      formGuid: "f125bf65-8121-4fa3-a67c-b51b6821bde9",
      heroSection: "",
      heroContent: "",
      heroImage: "",
      obsOptionsHero: {
        root: null,
        threshold: 0.1,
      },
      emailSubmitted: false,
    };
  },
  mounted() {
    this.heroSection = document.querySelector(".section-hero");
    this.heroContent = document.querySelector(".hero-content");
    this.heroImage = document.querySelector(".hero-img-box");
    const heroObserver = new IntersectionObserver(
      this.revealHero,
      this.obsOptionsHero
    );
    heroObserver.observe(this.heroSection);
  },
  methods: {
    revealHeroContent() {
      this.heroContent.classList.remove("hidden-content");
    },
    revealHeroImage() {
      this.heroImage.classList.remove("hidden-img");
    },
    revealHero() {
      this.revealHeroContent();
      this.revealHeroImage();
    },
    async submitNewsletter() {

      const response = await fetch(
        `https://api.hsforms.com/submissions/v3/integration/submit/${this.portalId}/${this.formGuid}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fields: [
              {
                objectTypeId: "0-1",
                name: "email",
                value: this.userEmail,
              },
            ],
          }),
        }
      );
      
      if (response.ok) {
        this.emailSubmitted = true;
      }
    },
  },
};
</script>

<style scoped>
.section-hero {
  position: relative;
  padding: 12.8rem 0 9.6rem 0;
}

.hero {
  max-width: 130rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.hero-img-box {
  display: flex;
  justify-content: right;
  align-items: center;
  transition: all 0.3s ease-in;
}

.hero-img {
  width: 52rem;
  display: inline-block;
  transform: skew(5deg);
}

.hidden-img {
  transform: translateX(9.6rem);
  opacity: 0;
}

.hidden-content {
  transform: translateX(-9.6rem);
  opacity: 0;
}

.hero-content {
  transition: all 0.3s ease-in;
}

.hero-description {
  line-height: 1.6;
  margin-bottom: 4.8rem;
  max-width: 100rem;
  color: #333;
}

.join-flex {
  margin-top: 2.4rem;
  display: flex;
  align-items: center;
}

.join-users {
  display: flex;
  margin-right: 1.6rem;
}

.join-users img {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  border: 3px solid #fff;
  margin-right: -0.8rem;
}

.join-text {
  font-size: 1.8rem;
  font-weight: 600;
}

.join-text > span {
  color: rgb(105, 59, 245, 1);
  font-weight: 700;
}

form {
  width: 80%;
  display: flex;
  align-items: center;
}

input {
  transition: all 0.2s ease-in;
  border-radius: 8px;
  width: 100%;
  margin-right: 1.8rem;
  padding: 1.2rem;
  font-size: 1.8rem;
  font-family: inherit;
  background: #f7f8fc;
  border: 2px solid transparent;
  color: #a5a5a5;
}

input:hover,
input:focus {
  outline: none;
  border: 2px solid #33cc99;
}

::placeholder {
  color: #a5a5a5;
}

button {
  font-family: inherit;
  cursor: pointer;
  font-weight: 500;
  color: #fff;
  transition: all 0.2s;
  padding: 10px 20px;
  border-radius: 0.8rem;
  background-image: linear-gradient(
    to right,
    rgb(105, 59, 245, 1),
    rgb(105, 59, 245, 0.85),
    rgb(105, 59, 245, 0.7)
  );
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  font-size: 2rem;
}

button:hover {
  box-shadow: 0 0.4rem 2.4rem rgb(105, 59, 245, 0.6);
  transform: scale(1.05);
}

button:active {
  transform: scale(0.95);
}

/* **************************** */
/* BELOW 1360px (Small desktop/laptop) */
/* **************************** */
@media (max-width: 85em) {
  .hero {
    max-width: 120rem;
  }

  .heading-primary {
    font-size: 6rem;
  }

  .hero-img {
    width: 48rem;
  }
}

/* **************************** */
/* BELOW 976px (landscape tablets) */
/* **************************** */
@media (max-width: 61em) {
  .heading-primary {
    font-size: 5.6rem;
  }

  .hero-img {
    width: 44rem;
  }
}

/* **************************** */
/* BELOW 800px (tablet) */
/* **************************** */
@media (max-width: 50em) {
  .hero-content {
    width: 70%;
  }

  .hero-img {
    position: absolute;
    right: 0%;
    transform: translateX(45%);
  }

  .hero-img-box {
    transition: all 0s ease-in;
  }

  .hidden-img {
    position: absolute;
    right: 0%;
    transform: translateX(45%);
    opacity: 0;
  }

  form {
    width: 100%;
  }
}

/* **************************** */
/* BELOW 592px (mobile) */
/* **************************** */
@media (max-width: 37em) {
  .heading-primary {
    font-size: 5rem;
  }

  .hero-img {
    display: none;
  }

  .hero-content {
    width: 100%;
  }
}

/* **************************** */
/* BELOW 496px (mobile) */
/* **************************** */
@media (max-width: 31em) {
  .heading-primary {
    font-size: 4.4rem;
  }

  .hero-img {
    right: 0%;
    bottom: 0%;
    transform: translate(40%, 55%);
  }

  .join-flex {
    flex-direction: column;
    align-items: flex-start;
  }

  .join-users {
    margin-bottom: 1.2rem;
  }

  button {
    padding: 8px 16px;
  }
}

/* **************************** */
/* BELOW 432px (mobile) */
/* **************************** */
@media (max-width: 27em) {
  .hero-img {
    width: 36rem;
    transform: translate(40%, 65%);
  }
}
</style>
