<template>
  <newsletter-hero></newsletter-hero>
  <newsletter-samples></newsletter-samples>
</template>

<script>
import NewsletterHero from "../components/newsletter/NewsletterHero.vue";
import NewsletterSamples from "../components/newsletter/NewsletterSamples.vue";

export default {
  components: {
    "newsletter-hero": NewsletterHero,
    "newsletter-samples": NewsletterSamples,
  },
};
</script>
